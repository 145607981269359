

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="title">
				General Settings
			</div>
		</div>
		<div style="padding: 25px 15px; background: #fff; margin-bottom: 10px; border: 1px solid #ddd; border-top: 0;">

			<div style="max-width: 600px; margin: auto; ">
				<form @submit.prevent="saveSettings">
					<div class="form-group" v-if="adsImage">
						<img :src="adsImage" alt="" style="display: block; width: 100%;" />
					</div>
					<div class="form-group">
						<label>
							Center Ads
							<input type="file" class="form-control" ref="center_ads" accept="image/*">
						</label>
					</div>
					<div class="form-group">
						<label>
							Reporting Helpline Number
							<input type="text" v-model="options.reporting_helpline_number" class="form-control"> 
						</label>
					</div>

					<div class="form-group clearfix">
						<button type="submit" :disabled="saving" class="btn btn-primary pull-right">Save</button>
					</div>
				</form>
			</div>
		</div>

	</div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast
	},
	data() {
		return {
			saving: false,
			options: {
				reporting_helpline_number: ''
			},
			uploadPercentage: 0,

			adsImage: '',
		}
	},
	mounted() {
		this.loadOptions();
	},
	methods: {
		loadOptions() {
			this.get("admin/load-options").then(res => {
				for(let i in res){
					this.options[i] = res[i];
				}

				if (res.center_ads) this.adsImage = window.apiOrigin + '/images/center/' + res.center_ads;
			});
		},
		saveSettings() {
			if (this.saving) return;
			this.saving = true;

			let center_ads = this.$refs['center_ads'].files[0];

			let data = {
				reporting_helpline_number: this.options.reporting_helpline_number,
			};
			if (center_ads) data['center_ads'] = center_ads;

			this.upload("admin/save-options", data, (err, res) => {
				this.saving = false;
				this.loadOptions();

				if (res.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Options saved successfully.', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			})

		}
	}
}
</script>
<style scoped>
label {
	display: block;
}
</style>
